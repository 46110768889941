import React, {useState, useContext} from 'react';

import {AuthContext} from '../../../../context/auth-context';

import {VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../../../../util/validators';
import PhoneInput from 'react-phone-number-input';

import {useForm} from '../../../../hooks/form-hook'

import { useHttpClient } from '../../../../hooks/http-hook'

import Modal from '../../../../UI_Elements/Modal/Modal'
import Input from '../../../../UI_Elements/Input/Input'

import OTP from '../../UI_Elements/OTP/OTP'
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop';
import { useNavigate } from 'react-router-dom';

function AddCustomerModal(props) {

    const history = useNavigate()

  const auth = useContext(AuthContext);

  const [otp, setOtp] = useState("");

  const [phoneValue, setPhoneValue] = useState("");

  const {isLoading, error, sendRequest} = useHttpClient();

  const [formState,
    inputHandler] = useForm({
    firstname: {
        value: '',
        isValid: false
    },
    lastname: {
        value: '',
        isValid: false
    },
    phonenumber: {
        value: '',
        isValid: false
    },
    email: {
        value: '',
        isValid: false
    },
    password: {
        value: '',
        isValid: false
    },
    confirmPassword: {
        value: '',
        isValid: false
    }
}, false);
  
  const policySubmitHandler = async (e) =>{
    e.preventDefault();
    const phonevalue = phoneValue.substring(1);

    const data = JSON.stringify({
      firstname: formState.inputs.firstname.value,
      lastname: formState.inputs.lastname.value,
      email: formState.inputs.email.value,
      phone_number: phonevalue,
      password: formState.inputs.password.value,
      password_confirmation: formState.inputs.confirmPassword.value
  })


    try {
        await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/customers/create`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
      
        history(0)


  } catch (err) {
      console.log(error)
  }
  }

  const otpHandler = (otp) =>{
    setOtp(otp)
  }
    
  return (
    
      <Modal show={props.show} onCancel={props.onCancel} onSubmit={policySubmitHandler} absolute>
        {isLoading && <Backdrop/>}
          <div className="bg-white p-2 md:p-12 lg:p-12">
          <h1 className="dash__blue__color text-center text-xl mb-4 font-semibold xl:mb-8">Add Customer</h1>
            <div className='flex flex-col gap-6 justify-center items-center lg:w-3/5 md:w-4/5 w-full mx-auto' >

            <Input
                            type="text"
                            containerClassName="lg:w-3/5 md:w-4/5 w-full"
                            placeholder='Enter First Name'
                            label="First Name"
                            id="firstname"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a first name."
                            onInput={inputHandler}/>

                        <Input
                            type="text"
                            containerClassName="lg:w-3/5 md:w-4/5 w-full"
                            placeholder='Enter Last Name'
                            label="Last Name"
                            id="lastname"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Please enter a Last name."
                            onInput={inputHandler}/>

                        <Input
                            type="email"
                            containerClassName=""
                            placeholder='Enter Email Address'
                            label="Email Address"
                            id="email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email"
                            onInput={inputHandler}/> 

                        <div className='flex flex-col  w-full'>
                            <label className='text-sm mb-2'>Phone Number</label>
                            <div className='border-2 p-2 rounded-sm border-slate-300 bg-white '>
                                <PhoneInput
                                    className="border-slate-300"
                                    defaultCountry="NG"
                                    withCountryCallingCode
                                    placeholder="Enter phone number"
                                    value={phoneValue}
                                    onChange={setPhoneValue}/>
                            </div>
                        </div>
                        <Input
                            type="password"
                            containerClassName="lg:w-3/5 md:w-4/5 w-full"
                            placeholder='Enter password'
                            label="Password"
                            id="password"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            errorText="Please enter more 8 characters"
                            onInput={inputHandler}/>

                        <Input
                            type="password"
                            containerClassName="lg:w-3/5 md:w-4/5 w-full"
                            placeholder='Enter password'
                            label="Confirm Password"
                            id="confirmPassword"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            errorText="Please enter more 8 characters"
                            onInput={inputHandler}/>

                <div className='my-6 md:my-12 flex flex-col justify-center items-start md:items-center '>
                  <p className='mb-2 text-left w-full'>Enter Admin PIN</p>
                  <OTP otp={otp} otpHandler={otpHandler}></OTP>
                </div>
                <button className='dash_button_red py-3 px-8 md:px-16 lg:px-24 rounded dash_button_pointer text-sm' type='submit'>Add Customer</button>
            </div>
        </div>
      </Modal>
  )
}

export default AddCustomerModal;
