import React, {useMemo, useEffect, useState, useContext, useCallback} from 'react'
import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';

import * as Icons from 'react-icons/io'

import { useHttpClient } from '../../hooks/dashboard-http-hook'
import {AuthContext} from '../../../../context/auth-context';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'
import StateButton from '../../../../UI_Elements/Button/StateButton';

// DATA
import {CUSTOMER_POLICY_COLUMNS} from '../../util/columns';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';



function CustomerPolicyTable(props) {    

    const auth = useContext(AuthContext)

    const history = useNavigate()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"5",
        "status":"1"
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        // const formData = new FormData()
        // formData.append('page_number',detailsData.page_number);
        // formData.append("customer_id", props.authId)

        const data = JSON.stringify({
            'page_number': detailsData.page_number,
            'customer_id':props.authId
        })

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/customers/policy?page=${detailsData.current_page}`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                setTableData(responseData.policy);
                setPages(responseData.policy.links)
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.policy.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.policy.policy_id,
                            "policy": file.policy.policy_name,
                            "insurer": file.attached_policy.company.company_name,
                            "premium": file.attached_policy.amount,
                            "start_date": moment(file.created_at).format('lll'),
                            "expiry_date": moment(file.created_at).add(1, 'years').format('lll'),
                            "status": file.status,
                            "actions": (moment(file.created_at).add(1, 'years').format('lll') >= moment(new Date()).format("lll")) ? 'insured' : 'not insured'
                        }])
                    }
                }



            } catch (err) {
                
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData, props.authId]);


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const singleCompany = useCallback((e, id) =>{
        e.preventDefault()
        history(id)
    },[history])


    const columns = useMemo(() => CUSTOMER_POLICY_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>
        {isLoading && <Backdrop overlay/>}
        
        <div className="bg-white pb-16 shadow rounded-xl mt-8 lg:mt-4 overflow-x-auto">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs">{column.render('Header')}</th>
                                ))}

                            <td className="text-xs">{" "}</td>
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {
                                        if(cell.value === "1"){
                                            return <td {...cell.getCellProps()} ><StateButton  neutral>
                                            PENDING
                                        </StateButton></td>
                                        }
                                        if(cell.value === "2"){
                                            return <td {...cell.getCellProps()} ><StateButton>
                                            ACTIVE
                                        </StateButton></td>
                                        }
                                        if(cell.value === "3"){
                                            return <td {...cell.getCellProps()} ><StateButton expired>
                                            EXPIRED
                                            </StateButton></td>
                                        }

                                        if(cell.value === "insured"){
                                            return <td {...cell.getCellProps()} ><div className="dash_button_neutral rounded-md text-center p-2 text-sm whitespace-nowrap">Re-insured</div></td>
                                        }

                                        if(cell.value === "not insured"){
                                            return <td {...cell.getCellProps()} ><div className="dash_button_red rounded-md text-center p-2 text-sm whitespace-nowrap">Re-insured</div></td>
                                        }
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}

                                    <td className='py-1 px-4 m-2 bg-transparent cursor-pointer' onClick={(e) => singleCompany(e,row.original.id)}><Icons.IoIosArrowDroprightCircle className="dash_action_button text-base"/></td>
                                
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
           {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}
            
        </div>
        </>              
    )
}

export default CustomerPolicyTable
