import React from "react";
import PropTypes from "prop-types";

export default function CardStats({
  statSubtitle,
  statTitle,
  statNumber,
  statDate,
}) {
  return (
    <>
      <div className="relative flex flex-col justify-center  min-w-0 break-words bg-white rounded-2xl mb-6 xl:mb-0 shadow dash__border__div">
       <div className="p-4 py-6">
       <p className="text-sm dash__color  mb-3">
          {statTitle}
        </p>

        <h1 className="text-primary-black text-lg mb-3 font-bold">
          {statNumber}
        </h1>

        <p className="text-xs">
          <span className="text-primary-black opacity-60">{statSubtitle}</span> <span className="text-primary-red">{statDate}</span>
        </p>
       </div>

      </div>
    </>
  );
}

CardStats.defaultProps = {
  statSubtitle: "",
  statTitle: "Total Payments",
  statNumber: "N350,897,300",
  statDate: "this month",
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statNumber: PropTypes.string,
  statDate: PropTypes.string
  
};
