import React, {useState} from 'react'
import CustomerTable from '../Components/Customers/CustomerTable';

import AddCustomerModal from '../Components/Customers/AddCustomerModal'

function Customers(){

    const [addCustomers, setAddCustomers] = useState(false)

    const addCustomerHandler = () =>{
        setAddCustomers(!addCustomers)
    }
    return(
        <>
            <AddCustomerModal show={addCustomers} onCancel={addCustomerHandler}/>
            <CustomerTable onAddPolicyHandler={addCustomerHandler}/>
        </>
        
    )

}

export default Customers;