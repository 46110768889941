import React, {useState, useContext} from 'react';

import {AuthContext} from '../../../../context/auth-context';

import {VALIDATOR_MINLENGTH} from '../../../../util/validators'

import {useForm} from '../../../../hooks/form-hook'

import { useHttpClient } from '../../../../hooks/http-hook'

import Modal from '../../../../UI_Elements/Modal/Modal'
import Input from '../../../../UI_Elements/Input/Input'

import OTP from '../../UI_Elements/OTP/OTP'
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop';

function EditPolicyModal(props) {

  const auth = useContext(AuthContext)

  const [otp, setOtp] = useState("")
  const {isLoading, error, sendRequest} = useHttpClient();

  const [formState,
    inputHandler] = useForm({

    policy: {
        value: '',
        isValid: false
    }
}, false);
  
  const policySubmitHandler = async (e) =>{
    e.preventDefault()

    const formData = new FormData();

    

    formData.append("policy_name", formState.inputs.policy.value);
    formData.append("policy_id", props.policy_id)
    formData.append("transaction_pin", otp);


    try {
      await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/policy/edit`, "POST", formData , {Authorization: 'Bearer '+auth.token});


  } catch (err) {
      console.log(error)
  }
  }

  const otpHandler = (otp) =>{
    setOtp(otp)
  }
    
  return (
    
      <Modal show={props.show} onCancel={props.onCancel} onSubmit={policySubmitHandler} >
        {isLoading && <Backdrop/>}
          <div className="bg-white p-2 md:p-8">
          <h1 className="dash__blue__color text-center text-xl mb-4 font-semibold">Edit Policy</h1>
            <div className='flex flex-col justify-center items-center w-full' >

            <Input
                            type="text"
                            placeholder='Enter policy name'
                            label="Title"
                            initialValue={props.policy_name}
                            initialValid={true}
                            id="policy"
                            validators={[VALIDATOR_MINLENGTH(2)]}
                            errorText="Please enter a valid policy"
                            onInput={inputHandler}
                            inputClassName="w-full"
                            containerClassName="w-full md:w-4/5 xl:w-3/5 "/>

                <div className='my-6 md:my-12 flex flex-col justify-center items-start md:items-center md:w-4/5'>
                  <p className='mb-2 text-left w-full lg:w-4/5'>Enter PIN</p>
                  <OTP otp={otp} otpHandler={otpHandler}></OTP>
                </div>
                <button className='dash_button_red py-3 px-8 md:px-16 lg:px-24 rounded dash_button_pointer' type='submit'>Edit Policy</button>
            </div>
        </div>
      </Modal>
  )
}

export default EditPolicyModal;
