import React, { useState } from 'react';

import HOC from '../HOC/HOC'

import Account from '../Components/Settings/Account'

import Password from '../Components/Settings/Password'




function Settings() {

    const [account, setAccount] = useState("account")

    const accountHandler = (event)=>{
        event.preventDefault();
    
        setAccount("account")
    }

    const passwordHandler = (event)=>{
        event.preventDefault();
    
        setAccount("password")
    }


  return (
      <HOC>
          <div className='grid lg:grid-cols-5'>
              <div className='col-span-1 flex flex-col'>
                <button className={`${account === "account" ? 'dash_button_buy' : 'text-primary-black' }  text-sm pr-8 pl-2 py-2 text-right mb-2`} onClick={accountHandler}>Account</button>
                <button className={`${account === "password" ? 'dash_button_buy' : 'text-primary-black' }  text-sm pr-8 pl-2 py-2 text-right`} onClick={passwordHandler}>Password & Security</button>
              </div>
              <div className='lg:col-span-4'>
                {
                    account === "account" ? <Account/> : <Password/>
                }

              </div>
          </div>
      </HOC>
  )
}

export default Settings;
