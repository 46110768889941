import React, {useMemo, useEffect, useState, useContext} from 'react'
// import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';

// import * as Icons from 'react-icons/md'
import * as IconT from 'react-icons/bi'

import { useHttpClient } from '../../hooks/dashboard-http-hook'
import {AuthContext} from '../../../../context/auth-context';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

// DATA
import {POLICY_COLUMNS , POLICY_ATTACHMENT_COLUMNS} from '../../util/columns';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';
import EditPolicyModal from './EditPolicyModal';
import DeletePolicyModal from './DeletePolicyModal'



function PolicyTable(props) {    

    const auth = useContext(AuthContext)

    const [query,
        setQuery] = useState("");

    // const history = useNavigate()

    const [editData, setEditData] = useState({
    });
    
    const [isEditPolicy, setIsEditPolicy] = useState(false)

    const [isDeletePolicy, setIsDeletePolicy] = useState(false)

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"10",
        "status":"1",
        "id":"0",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        // formData.append('status',detailsData.status);
        formData.append("search_text", detailsData.search_text)

        const fetchAttachmentPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/policy/attachment/list?page=${detailsData.current_page}`, "POST", formData , {Authorization: 'Bearer '+auth.token});
                
                setTableData(responseData.data);
                setPages(responseData.data.links)

                if(responseData){
                    setCellData([])
                    for(let file of responseData.data.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.policy.policy_id,
                            "policy": file.policy.policy_name,
                            "date": moment(file.policy.created_at).format('lll'),
                            "customers": file.customer_count,
                            "company":file.company.company_name
                           
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };


        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/policy/list?page=${detailsData.current_page}`, "POST", formData , {Authorization: 'Bearer '+auth.token});
                
                setTableData(responseData.policy);
                setPages(responseData.policy.links)

                if(responseData){
                    setCellData([])
                    for(let file of responseData.policy.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.policy_id,
                            "policy": file.policy_name,
                            "date": moment(file.created_at).format('lll'),
                            "policy_id":file.policy_id
                            
                           
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        
        if(detailsData.id === "1"){
            fetchAttachmentPolicies()
        }
        else{
            const res = () =>{
                fetchPolicies();
                
            };

            res()
            
        }
        
        

    }, [sendRequest, error, auth, detailsData, isEditPolicy]);


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }



    const columns = useMemo(() => detailsData.id === "0" ? POLICY_COLUMNS : POLICY_ATTACHMENT_COLUMNS, [detailsData])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    const handler = () => {
        setDetailsData({
            ...data,
            search_text:query
        })
    }

    function editCancel(name, id){
        setEditData({
            name:name,
            id:id
        })
        setIsEditPolicy(!isEditPolicy)
    }


    function deleteCancel(name,id){
        setEditData({
            name:name,
            id:id
        })
        setIsDeletePolicy(!isDeletePolicy)
    }

    



    return (
        <>
        {isLoading && <Backdrop overlay/>}
        {isEditPolicy && <EditPolicyModal show={isEditPolicy} onCancel={editCancel} policy_name={editData.name} policy_id={editData.id}/>}
        {isDeletePolicy && <DeletePolicyModal show={isDeletePolicy} onCancel={deleteCancel} policy_id={editData.id} /> }
        
        <div className='flex justify-between items-center flex-wrap mb-8'>
            <div
                    className='flex items-center justify-between rounded-full shadow-sm m-2 p-3 w-full lg:w-2/5 xl:w-1/5 bg-white'>
                    <input
                        type="text"
                        className='text-sm outline-none flex-grow'
                        placeholder='search...'
                        id='search_select'
                        onInput={(e) => {
                        e.preventDefault();
                        setQuery({value: e.target.value});
                        handler();
                    }}/>
                    <label htmlFor='search_select'>
                        <IconT.BiSearch className='text-slate-500'/>
                    </label>
                </div>

                <button className='dash_button_red px-4 xl:px-6 py-2 rounded text-center text-sm dash_button_pointer' onClick={props.onAddPolicyHandler}>
                + Add Policy
                </button>

        </div>


        <nav className="dash_border-b my-4  w-full">
            <button className={` py-2 px-6  dash__color ${detailsData.id === "0" && "border-b-2 border-primary-red"}`}  onClick={
                () =>{
                    setDetailsData(data =>({
                        ...data,
                        "id":"0"
                    }))
                }
            }>Policy List</button>
            <button className={` py-2 px-6  dash__color ${detailsData.id  === "1"  && "border-b-2 border-primary-red"}`} onClick={
                () =>{
                    setDetailsData(data =>({
                        ...data,
                        "id":"1"
                    }))
                }
            }>Attached Policy List</button>
            
        </nav>
        
        <div className="bg-white pb-16 shadow-md sm:rounded-lg mt-8 overflow-x-auto">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap">{column.render('Header')}</th>
                                ))}
                            {detailsData.id === "0" && <th>Actions</th>}

                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}

                                {
                                    detailsData.id === "0" && (
                                        <td className="flex justify-start items-center">
                                            <button className="font-bold mr-2" onClick={() =>editCancel(row.original.policy, row.original.id)} >Edit</button>
                                            <button className='text-primary-red' onClick={() =>deleteCancel(row.original.policy, row.original.id)}>Delete</button>
                                        </td>
                                    )
                                }
                                
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
           {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}
            
        </div>
        </>              
    )
}

export default PolicyTable
