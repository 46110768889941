import React, {useMemo, useEffect, useState, useContext} from 'react'
// import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';
import csvDownload from 'json-to-csv-export'


import * as IconT from 'react-icons/bi'
import * as IconG from 'react-icons/gr'


import { useHttpClient } from '../../hooks/dashboard-http-hook'
import {AuthContext} from '../../../../context/auth-context';
import { usePdfClient} from '../../hooks/pdf-http-hook.js'

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

// DATA
import {PAYMENTS_COLUMNS} from '../../util/columns';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';
import { images } from '../../../../assests';



function PaymentTable() {    

    const auth = useContext(AuthContext)

    const [query,
        setQuery] = useState("");

    // const history = useNavigate()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"20",
        "status":"1",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    const {sendPdfRequest} = usePdfClient()

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);

        const data = JSON.stringify({
            "page_number": detailsData.page_number,
            "id": detailsData.status,
            "search_text": detailsData.search_text
        })

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/payments/list?page=${detailsData.current_page}`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                setTableData(responseData.payments);
                setPages(responseData.payments.links)
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.payments.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.reference,
                            "date": moment(file.created_at).format('lll'),
                            "policy": file.policyname,
                            "insurer": file.insurer,
                            "amount": file.amount,
                            "customer": `${file.customer.lastname} ${file.customer.firstname}`
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);

    const singlePayments = async(e,id) =>{
        
        try{
            const responseData = await sendPdfRequest(`${process.env.REACT_APP_API_CALL}/admins/payments/recipt/${id}`, "GET", null , {Authorization: 'Bearer '+auth.token, Accept: "application/*"});
            window.open(URL.createObjectURL(responseData), '_blank')
            
            
        }
        catch(err){
            console.log(error)
        }
    }  


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const columns = useMemo(() => PAYMENTS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    const handler = () => {
        setDetailsData({
            ...data,
            search_text:query
        })
    }

    return (
        <>
        {isLoading && <Backdrop overlay/>}
        <div className='flex justify-between items-center flex-wrap mb-8'>
            <div
                    className='flex items-center justify-between rounded-full shadow-sm m-2 p-3 w-full lg:w-2/5 xl:w-1/5 bg-white'>
                    <input
                        type="text"
                        className='text-sm outline-none flex-grow bg-white'
                        placeholder='search...'
                        id='search_select'
                        onInput={(e) => {
                        e.preventDefault();
                        setQuery({value: e.target.value});
                        handler();
                    }}/>
                    <label htmlFor='search_select'>
                        <IconT.BiSearch className='text-slate-500'/>
                    </label>
                </div>

                <div className="flex justify-center items-baseline text-primary-black mt-2 ml-2 md:mt-0 md:ml-0 cursor-pointer" onClick={() => csvDownload(cellData, "PaymentsData.csv")}>
                    <IconG.GrDownload className='text-sm mr-2'/>
                    <p className='text-sm' >Export table</p>
                </div>

        </div>
        
        <div className="bg-white pb-16 shadow-md sm:rounded-lg mt-16 overflow-x-auto">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap ">{column.render('Header')}</th>
                                ))}

                            <th className="text-xs">Reciept</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-6 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}
                                

                                <td className='py-4 px-6 m-2 flex items-center bg-transparent cursor-pointer text-sm whitespace-nowrap' onClick={(e) => singlePayments(e,row.cells[0].value)}>
                                    <img src={images.AdobeDownload[0]} alt={images.AdobeDownload[1]}/>
                                    <p className='ml-2 text-primary-red'>Download</p>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}
            
        </div>
        </>              
    )
}

export default PaymentTable
