import React, { useCallback, useContext, useEffect, useState } from 'react'

import HOC from '../HOC/HOC'

import * as IconT from 'react-icons/io'

import CardStats from '../../../components/Cards/CardStats'
import DashboardTable from '../Components/Dashboard/DashboardTable'
import { useHttpClient } from '../hooks/dashboard-http-hook'
import { AuthContext } from '../../../context/auth-context'
import Backdrop from '../../../UI_Elements/Backdrop/Backdrop'
import { useNavigate } from 'react-router-dom'

function DashboardOverview (){
    const auth = useContext(AuthContext)

    const history = useNavigate()

    const [dashboardData, setDashboardData] = useState(null)

    const {isLoading, error, sendRequest} = useHttpClient();

    
    useEffect(() => {

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/dashboard`, "GET", null , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                setDashboardData(responseData)



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth]);

    const singleCustomer = useCallback(( id) =>{
        console.log(id)
        history(`../customers/${id}`)
    },[history])


    return (
        <>
        {isLoading && <Backdrop/>}
        {dashboardData && (
            <HOC>
            <div className='grid-cols-1  lg:grid-cols-2 xl:grid-cols-8 grid gap-8 mb-8 lg:mb-16'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 col-span-3'>
                    <CardStats statDate="Today" statNumber={`N ${dashboardData.payment_count_today}`}/>

                    <CardStats 
                    statDate="this month" 
                    statNumber={`${dashboardData.insurance_company_count}`} 
                    statSubtitle={`${dashboardData.insurance_company_count_month} added`} 
                    statTitle="Total Companies"/>

                    <CardStats 
                    statDate="this week"    
                    statNumber={`${dashboardData.total_claims}`} 
                    statSubtitle={`${dashboardData.this_week_claims} new claims`} 
                    statTitle="Total Claims"/>

                    <CardStats 
                    statDate="this month" 
                    statNumber={`${dashboardData.customer_count}`} 
                    statSubtitle={`${dashboardData.customer_month_count} signups`} 
                    statTitle="Total Customers"/>
                </div>

                <div className='rounded-lg bg-white p-4 shadow dash__border__div col-span-3'>
                    <h3 className='dash__color  mb-2 text-xs'>
                        Recent Customer Signups
                    </h3>
                    <div>
                        {dashboardData.recent_customers.slice(0,5).map((item, index) => {
                            return (
                                <div className={`flex justify-between items-center p-2 ${index !== 4 && "dash_border-b"}`} key={index}>
                                    <div className="flex flex-col ">
                                        <h3 className=' text-primary-black text-sm'>{`${item.firstname} ${item.lastname}`}</h3>
                                        <p className='text-primary-black opacity-60 text-sm'>{`${item.email}`}</p>
                                    </div>
                                    <IconT.IoIosArrowDroprightCircle onClick={()=>{
                                        singleCustomer(item.authId)
                                    }} className="text-primary-red opacity-60 text-base cursor-pointer"/>
                                </div>
                            )
                        })} 

                        
                    </div>

                </div>

                <div className='rounded-lg bg-white p-4 shadow dash__border__div col-span-2'>
                    <h3 className='dash__color mb-2 text-xs'>
                        Recently Added Policies
                    </h3>
                    <div>
                    {dashboardData.recent_policy.slice(0,5).map((item, index) => {
                            return (
                                <div className='flex justify-between items-center p-2 dash_border-t' key={index}>
                                    <h3 className=' text-primary-black py-2 text-sm'>{item.policy_name}</h3> 
                                </div>
                            )
                        })} 
                        

                        

                    </div>

                </div>
            </div>

            <DashboardTable/>
        </HOC>
        )}

        </>
    )
}

export default DashboardOverview