import React, {useMemo, useEffect, useState, useContext} from 'react'
// import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';



import { useHttpClient } from '../../hooks/dashboard-http-hook'
import { usePdfClient} from '../../hooks/pdf-http-hook.js'
import {AuthContext} from '../../../../context/auth-context';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

// DATA
import {PAYMENTS_COLUMNS} from '../../util/columns';
import { images } from '../../../../assests';



function PaymentTable() {    

    const auth = useContext(AuthContext)


    const [cellData , setCellData] = useState([]);

    const detailsData = useMemo( () =>{
        return {
            "current_page" : '1',
            "page_number":"5",
            "status":"1"
        }
    }
        
    ,[])


    const {isLoading, error, sendRequest} = useHttpClient();

    const {sendPdfRequest} = usePdfClient()

    useEffect(() => {

        

        const data = JSON.stringify({
            "page_number": detailsData.page_number,
            "id": detailsData.status
        })

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/payments/list?page=${detailsData.current_page}`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.payments.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.reference,
                            "date": moment(file.created_at).format('lll'),
                            "policy": file.policyname,
                            "insurer": file.insurer,
                            "amount": file.amount,
                            "customer": `${file.customer.lastname} ${file.customer.firstname}`
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);


    const singlePayments = async(e,id) =>{
        
        try{
            const responseData = await sendPdfRequest(`${process.env.REACT_APP_API_CALL}/admins/payments/recipt/${id}`, "GET", null , {Authorization: 'Bearer '+auth.token, Accept: "application/*"});
            window.open(URL.createObjectURL(responseData), '_blank')
            
            
        }
        catch(err){
            console.log(error)
        }
    }   


    

    const columns = useMemo(() => PAYMENTS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)


    return (
        <>
        {isLoading && <Backdrop overlay/>}
        <div className='flex justify-between items-center flex-wrap my-8'>
            <h1 className="font-semibold ">Recent Payments</h1>      

        </div>
        
        <div className="bg-white pb-16 shadow rounded-xl mt-4 overflow-x-auto w-full lg:w-6/6 xl:w-4/5">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs whitespace-nowrap">{column.render('Header')}</th>
                                ))}

                            <th className="text-xs">Reciept</th>
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>
                                
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}
                                <td className='py-4 px-6 m-2 mr-4 flex items-center bg-transparent cursor-pointer text-sm whitespace-nowrap' onClick={(e) => singlePayments(e,row.original.id)}>
                                    <img src={images.AdobeDownload[0]} alt={images.AdobeDownload[1]}/>
                                    <p className='ml-2 text-primary-red'>Download</p>
                                </td>
                            </tr>

                            
                        )
                    })}
                    
                    

                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            
            
        </div>
        </>              
    )
}

export default PaymentTable
