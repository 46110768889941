import React from 'react'

import OtpInput from 'react-otp-input';

function OTP(props) {

  return (
    <>
        <OtpInput
        value={props.otp}
        onChange={props.otpHandler}
        numInputs={4}
        separator={<span className='w-4 md:w-8'>{""}</span>}
        isInputNum
        isInputSecure 
        inputStyle={'dash__otp dash__border__otp rounded text-primary-black'}
      />
    </>
  )
}

export default OTP