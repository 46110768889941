import React from 'react';

import HOC from '../HOC/HOC';

import ClaimsTable from '../Components/Claims/ClaimsTable'
// import ClaimsDetails from '../Components/Claims/ClaimsDetails'

function Claims() {
    
  return (
      <HOC>
          <ClaimsTable/>
      </HOC>
      
  );
}


export default Claims;