import React, {useContext} from "react";

import { DataContext } from "../../../../pages/dashboard/context/DataContext";

import UserDropdown from "../../../../components/Dropdowns/UserDropdown";

export default function AdminNavbar() {

  const data = useContext(DataContext)
  return (
    <>
      {/* Navbar */}
      <nav className="relative bg-white w-full border-l-2 border-b-2 border-slate-200 md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-auto items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-primary-black hidden text-base font-normal uppercase md:inline-block"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            MY INSURANCES
          </a>
          {/* Form */}

          <div className="flex justify-center items-center">
          

          <div className="flex justify-center items-center">
            {/* User */}
            <p className="mr-2">{data.firstname}</p>
            <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
              <UserDropdown />
            </ul>
          </div>

          </div>


        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
