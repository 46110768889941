import React, { useEffect, useContext, useState, useCallback } from 'react'

import { Link, useParams } from 'react-router-dom';
import moment from 'moment'

import { saveAs } from 'file-saver';

import {images} from '../../../../assests/index'

import {AuthContext} from '../../../../context/auth-context';
import * as IconT from 'react-icons/io';
import * as IconH from 'react-icons/hi'

import DeleteClaimsModal from './DeleteClaimsModal';


import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

import {useHttpClient} from '../../hooks/dashboard-http-hook'
import SelectTable from '../../UI_Elements/Select/SelectTable';

function ClaimsItem() {
    const {id} = useParams();
    const auth = useContext(AuthContext)

    const [claimsData, setClaimsData] = useState(null);


    const { error, sendRequest} = useHttpClient();

    const [claimsImages, setClaimsImages] = useState([])

    const [claimsDocuments, setClaimsDocuments] = useState([])

    const [otp, setOtp] = useState("")

    const [showModal, setShowModal] = useState( false)

    const statusOptions = ["Settled" , "Pending", "Decline"];


    useEffect(() => {


        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/claims/single/${id}`, "GET",null, {Authorization: 'Bearer '+auth.token});
                setClaimsData(responseData.claims)
                setClaimsImages([])
                setClaimsDocuments([])
                
                setClaimsImages(()=> [
                    ...responseData.claims.images
                ])

                setClaimsDocuments(() => [
                    ...responseData.claims.documents
                ])
                

                
                


            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, id]);


    const onStatusChangeHandler = useCallback(async(item) =>{
       
        const data = JSON.stringify({
            "status": item,
            "claim_id": claimsData.claim_id
        })

        
            
        try {   
            
            await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/claims/change/status`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});


        } catch (err) {
            
            console.log(error)
        }
        
    },[auth, sendRequest, error, claimsData])


    const statusNumber = useCallback((item) =>{
        
        let number;
        if(item === "Pending"){
            number = 0;

            return onStatusChangeHandler(number)
        }
        if(item === "Settled"){
            number = 1;

            return onStatusChangeHandler(number)
        }
        if(item === "Decline"){
            number = -1;

            return onStatusChangeHandler(number)
        }
    },[onStatusChangeHandler])

    const cancelDeleteModalHandler = () => {
        setShowModal(!showModal)
        
    }

    const otpHandler = (otp) =>{
        setOtp(otp)
    }

    
    return (
        
            <>

        {claimsData === null ? <Backdrop/> : ( 
            <>
            <DeleteClaimsModal show={showModal} onCancel={cancelDeleteModalHandler} otp={otp} otpHandler={otpHandler}/>
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <Link to="../claims" className="flex justify-center items-center">
                    <IconT.IoIosArrowDropleftCircle className="dash_action_button text-base mr-2"/>
                    <p className="dash__color text-base font-normal">All Claims</p>
                </Link>

                <div className='flex flex-col md:flex-row justify-center items-center mt-4 md:mt-0'>     
                    <button className='py-2 px-8 dash_button_red rounded text-sm ' onClick={cancelDeleteModalHandler}>Delete Claim</button>
                </div>
                

            </div>

            
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-8">
                <div className='col-span-3 px-4 py-2 pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black">Claim Info</h1>

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Id</h3>
                            <p className='dash__text text-left break-words'>{claimsData.claim_id}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Date</h3>
                            <p className='dash__text text-left'>{moment(claimsData.created_at).format('lll')}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Circumstance</h3>
                            <p className='dash__text text-left'>{claimsData.circumstance}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Insuance Policy</h3>
                            <p className='dash__text text-left underline'>{claimsData.policyname}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Insurer</h3>
                            <p className='dash__text text-left'>{claimsData.insurer}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left'>Status</h3>
                            <div className='md:w-3/5 cursor-pointer'>
                                <SelectTable options={statusOptions} value={claimsData.status === "0" ? "Pending" : claimsData.status === "1" ? "Settled" : "Decline"}  onInput={statusNumber} />
                            </div>
                        </div>
                        
                        <div className=' sm:col-span-2 lg:col-span-3 py-2'>
                            <h1 className="pb-2 dash__color w-full ">Description</h1>
                            <p className='text-primary-black opacity-70 text-base py-2'>
                                {claimsData.description}
                            </p>
                        </div>
                       
                    </div>
                </div>

                <section className='col-span-3 lg:col-span-1 px-4 py-2 pb-8 md:pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4">Customer Info</h1>
                    <div>
                        <div className='flex justify-between items-center'>
                            <h3 className='text-primary-black opacity-70 text-sm mr-2 flex-wrap'>Name</h3>
                            <p className='text-primary-black text-sm'>
                                {`${claimsData.customer.firstname} ${claimsData.customer.lastname}`}
                            </p>
                        </div>
                        <div className='flex justify-between items-center py-8 flex-wrap'>
                            <h3 className='text-primary-black opacity-70 text-sm mr-2'>Email</h3>
                            <p className='text-primary-black text-sm'>
                                {claimsData.customer.email}
                            </p>
                        </div>
                        <div className='flex justify-between items-center flex-wrap'>
                            <h3 className='text-primary-black opacity-70 text-sm break-words mr-2'>Phone Number</h3>
                            <p className='text-primary-black text-sm'>
                                {claimsData.customer.phone_number}
                            </p>
                        </div>
                    </div>
                </section>


                <div className='col-span-3 lg:col-span-4 px-4 py-2 pb-8 md:pb-16 rounded-xl shadow bg-white'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4">Images</h1>

                    <div className="flex items-center gap-4">
                    
                        {claimsData !== null && claimsImages.map((img,index) => (
                            <div className='relative w-32 h-32 m-2' key={index}>
                                <img src={img} alt={index} className={`w-32 h-32 object-cover cursor-pointer`} onClick={() =>{
                                saveAs(img, `${img.split('/')[7]}`);
                            }}/>

                                
                            </div>
                            
                        ))}

                    </div> 
                    
                </div>


                <div className='col-span-3 lg:col-span-4 px-4 py-2 pb-8 md:pb-16 rounded-xl shadow bg-white mb-12'>
                    <h1 className="pb-2 dash__color w-full dash_border-b border-black mb-4">Documents</h1>
                    
                    <div className="flex items-center gap-4">
                        {claimsData !== null && claimsDocuments && claimsDocuments.map((document,index) => (
                            <div className='relative m-2' key={index} >

                                <div className="flex justify-between items-center p-4 py-6 w-64 overflow-hidden h-auto dash__card__shadow cursor-pointer rounded-3xl" 
                                onClick={() =>{
                                saveAs(document, `${document.split('/')[7]}`);
                            }}>
                                    <img src={images.AdobeDownload[0]} alt={images.AdobeDownload[1]} className=""/>
                                    <h3 className='font-bold  test-sm break-words'>{document.split('/')[7].length<10 ? document.split('/')[7] : `${document.split('/')[7].slice(0,10)}(..)`}.{document.split('/')[7].split(".")[1]}</h3>
                                    <IconH.HiArrowDown className="text-primary-red " />
                                </div>

                            
                            </div>
                            
                        ))}

                    </div>
                    
                </div>



            </div>
            </>
            )}
        </> 
        
    )
}

export default ClaimsItem
