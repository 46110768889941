import { createContext } from 'react';

export const DataContext = createContext({
    firstname:null,
    lastname:null,
    email:null,
    phonenumber:null,
    transactPin:null,
    adminType:null
});
