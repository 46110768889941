import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import AOS from "aos";

import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-phone-number-input/style.css';
import './App.css'

import Dashboard from './pages/dashboard/dashboard';
import Login from './auth/Login';



import { AuthContext } from './context/auth-context';
import { useAuth } from './hooks/auth-hook';

function App() {

    useEffect(() => {
        AOS.init({duration: 2000});
        AOS.refresh();
    }, []);

    const { token, login, logout, userId } = useAuth();

    let routes;

    if (token) {
        routes = (
            <Routes>
                <Route path='/admin/*' element={<Dashboard/>} />
                <Route path="*" element={<Navigate to ="/admin" />}/>
            </Routes>
        );
    } else {
        routes = (
            <Routes>
                
                <Route path='/login' exact element={< Login />}/>
                <Route path="*" element={<Navigate to ="/login" />}/>
            </Routes>
        );
    }

    return (
        <AuthContext.Provider
            value={{
            isLoggedIn: !!token,
            token:token,
            userId: userId,
            login: login,
            logout: logout
        }}>
            <Router>

                {routes}

            </Router>
        </AuthContext.Provider>
    );
}

export default App;