import React, {useState} from 'react'
import PolicyTable from '../Components/Policies/PolicyTable';

import AddPolicyModal from '../Components/Policies/AddPolicyModal'

function Policies(){

    const [addPolicy, setAddPolicy] = useState(false)

    const addPolicyHandler = () =>{
        setAddPolicy(!addPolicy)
        
    }
    return(
        <>
            <AddPolicyModal show={addPolicy} onCancel={addPolicyHandler}/>
            <PolicyTable onAddPolicyHandler={addPolicyHandler}/>
        </>
        
    )

}

export default Policies;