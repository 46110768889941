


export const PAYMENTS_COLUMNS = [
  {
    Header: 'Transaction ID',
    accessor: 'id',
    
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Customer',
    accessor: 'customer',
    
  },
  {
    Header: 'Policy',
    accessor: 'policy',
    
  },
  {
    Header: 'Insurer',
    accessor: 'insurer'
  },
  
  {
    Header: 'Amount',
    accessor: 'amount'
  }
  
]


export const CLAIMS_COLUMNS = [
  {
    Header: 'Claim ID',
    accessor: 'id',
    disableFilters: true
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Customer',
    accessor: 'customer'
  },
  {
    Header: 'Insurance Policy',
    accessor: 'policy'
  },
  {
    Header: 'Insurer',
    accessor: 'insurer'
  },
  {
    Header: 'Circumstance',
    accessor: 'circumstance'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
]


export const POLICY_COLUMNS = [
  {
    Header: 'Policy',
    accessor: 'policy'
  },
  {
    Header: 'Date Added',
    accessor: 'date'
  },
  {
    Header: 'Policy ID',
    accessor: 'policy_id'
  },
  
]


export const POLICY_ATTACHMENT_COLUMNS = [
  {
    Header: 'Policy',
    accessor: 'policy'
  },
  {
    Header: 'Date Added',
    accessor: 'date'
  },
  {
    Header: 'Customers',
    accessor: 'customers'
  },

  {
    Header: 'Company',
    accessor: 'company'
  }
  
]


export const COMPANY_COLUMNS = [
  {
    Header: 'Companies',
    accessor: 'companies'
  },
  {
    Header: 'Date Added',
    accessor: 'date'
  },
  {
    Header: 'Attached Policies',
    accessor: 'attach_policy'
  },
  {
    Header: 'Customers',
    accessor: 'customers'
  }

]

export const COMPANY_POLICY_COLUMNS = [
  {
    Header: 'Policy',
    accessor: 'policy'
  },
  {
    Header: 'Rates',
    accessor: 'rates'
  },
  {
    Header: 'Date Added',
    accessor: 'date'
  },
  {
    Header: 'Policy ID',
    accessor: 'policy_id'
  },
  
]


export const COMPANY_CUSTOMERS_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'Phone Number',
    accessor: 'phone_number'
  },
  {
    Header: 'Policy',
    accessor: 'policy'
  },
  
]


export const CUSTOMER_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'Phone Number',
    accessor: 'phone_number'
  },
  {
    Header: 'Policies',
    accessor: 'policies'
  }

]


export const CUSTOMER_POLICY_COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    
  },
  {
    Header: 'Policy',
    accessor: 'policy',
    
  },
  {
    Header: 'Insurer',
    accessor: 'insurer',
    
  },
  {
    Header: 'Premium',
    accessor: 'premium'
  },
  {
    Header: 'Start Date',
    accessor: 'start_date'
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiry_date'
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'Actions',
    accessor: 'actions'
  }

]