import React, {useContext} from 'react'

import Input from '../UI_Elements/Input/Input'
import Loader from '../UI_Elements/loader/Loader';

import {images} from '../assests/index'

import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
import {AuthContext} from '../context/auth-context';

import './auth.css'

import {VALIDATOR_EMAIL, VALIDATOR_MINLENGTH} from '../util/validators'
import { useNavigate } from 'react-router-dom';


function Login() {
    const history = useNavigate()

    const auth = useContext(AuthContext);

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const data = JSON.stringify({
                email: formState.inputs.email.value,
                password: formState.inputs.password.value
            })

            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/login`, 'POST', data, {'Content-Type': 'application/json'});
            auth.login(responseData.admin.adminId, responseData.token);
            history("/admin")

        } catch (err) {
            
            console.log(error)
        }
    };


    return (
        <>
        
        <div
            className='grid grid-cols-1 lg:grid-cols-3 relative pb-16 md:pb-16 lg:pb-32'>
            <img
                src={images.FormImage[0]}
                alt={images.FormImage[1]}
                className='hidden lg:block'/>
            <div className='mt-8 col-span-2 px-4 md:px-8 xl:px-32'>
                <div className='flex flex-col md:flex-row justify-between items-center'>
                    <img
                        src={images.logo[0]}
                        alt={images.logo[1]}
                        className='w-32 object-contain mb-8 md:mb-0'/>
                    
                </div>

                <div className='mt-8 md:mt-16 mb-10 '>
                    <h1 className='text__heading text-center md:text-left'>Login</h1>
                    <p className='text-base opacity-50 mt-2 text-center md:text-left'>Login to your admin account</p>
                </div>

                
                    <form className='md:w-4/5' onSubmit={authSubmitHandler}>
                    <div className='grid grid-cols-1 gap-8 md:w-4/5'>

                        <Input
                            type="email"
                            placeholder='sampleemail@gmail.com'
                            label="Email Address"
                            id="email"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Please enter a valid email"
                            onInput={inputHandler}/>

                        <Input
                            type="password"
                            placeholder=''
                            label="Password"
                            id="password"
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            errorText="Please enter more 8 characters"
                            onInput={inputHandler}/>

                    </div>

                    <div className='flex justify-start items-center mt-8 md:mt-12'>
                        <input
                            type='submit'
                            className={`form__submit button__padding text-white mr-2  `}
                            value="Login into account" 
                            /> {isLoading && <Loader/>}
                    </div>
                    

                </form>
                
            </div>

            <img
                src={images.CircleImage[0]}
                alt={images.CircleImage[1]}
                className='absolute -bottom-10 right-0 -z-10'/>
        </div>
        </>
    )
}

export default Login
