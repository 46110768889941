import React, { useEffect, useContext, useState } from 'react'

import {Link, useParams } from 'react-router-dom';
import moment from 'moment'

import {AuthContext} from '../../../../context/auth-context';
import {images} from '../../../../assests/index';
import * as IconT from 'react-icons/io';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'


import {useHttpClient} from '../../hooks/dashboard-http-hook';
import CustomerPolicyTable from './CustomerPolicyTable'
import Avatar from '../../UI_Elements/Avatar/Avatar';
import Select from '../../UI_Elements/Select/Select';


function CustomerItem() {
    const {id} = useParams();
    const auth = useContext(AuthContext)

    const [customersData, setCustomersData] = useState(null);
    const [action, setAction] = useState(null);

    const Actions = [
        "Suspend",
        "Active"
    ]

    const [profileImage,
        setProfileImage] = useState(null);

    const {error, sendRequest} = useHttpClient();

    


    useEffect(() => {


        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/customers/single/${id}`, "GET",null, {Authorization: 'Bearer '+auth.token});
                setCustomersData(responseData.customers)
                setProfileImage(responseData.customers.image)
                
                console.log(responseData)


            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, id]);

    useEffect(()=>{

        if(action){
            if(customersData.status === "0" && action === "Active"){
                return
            }

            else if(customersData.status === "1" && action === "Suspend"){
                return
            }

            else{
                const data = async() =>{
                    try {
                        await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/customers/toggle/${id}`, "GET",null, {Authorization: 'Bearer '+auth.token});
                        
            
            
                    } catch (err) {
                        console.log(error)
                    }
                }
        
                data()
            }

        }
        
    }, [sendRequest, error, auth, action, id, customersData])

    const inputAction = async(data) =>{  

        if(customersData){
            if(customersData.status === "0" && data === "Active"){
                return
            }

            else if(customersData.status === "1" && data === "Suspend"){
                return
            }

            else{
               setAction(data)
            }

        }
    }

    
    return (
        
            <>

         {customersData === null  ? <Backdrop/> : (
            <>
            
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <Link to="../companies" className="flex justify-center items-center" >
                    <IconT.IoIosArrowDropleftCircle className="dash_action_button text-base mr-2"/>
                    <p className="dash__color text-base font-normal">All Customers</p>
                </Link>

                <div className='flex flex-col md:flex-row justify-center items-center mt-4   md:mt-0'>     
                    <Select options={Actions} onInput={inputAction} placeholder="Select Action" value={customersData.status === "0" ? "Active" : "Suspend"}/>
                    
                    {/* <button className='py-2 px-6 md:px-8 dash_button_red text-sm rounded mt-4 md:mt-0 dash_button_pointer' onClick={cancelDeleteModalHandler}>Send Message</button> */}
                </div>
                

            </div>

            
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-4">
                <div className='col-span-3 relative'>

                <div className=' px-6 py-8 rounded-xl shadow bg-white'>
                    
                    <span className='flex justify-between items-center flex-wrap sm:flex-nowrap dash_border-b '>
                        <h1 className="pb-2 dash__color w-full">Customer Info</h1>
                    </span>

                    
                    
                    <Avatar src={profileImage ? profileImage : images.AvatarImage[0] } alt="account profile"  className="mx-auto w-16 h-16 rounded-full absolute -top-4 left-3/4 sm:left-1/2" />
                    

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 place-center gap-4'>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Name</h3>
                            <p className='dash__text text-left break-words'>{`${customersData.firstname} ${customersData.lastname}`}</p>
                        </div>

                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Email Address</h3>
                            <p className='dash__text text-left break-words'>{customersData.email}</p>
                        </div>
                        
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Phone Number</h3>
                            <p className='dash__text text-left '>{customersData.phone_number}</p>
                        </div>

                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Policies</h3>
                            <p className='dash__text text-left break-words'>{customersData.policycount}</p>
                        </div>

                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Date joined</h3>
                            
                            <p className='dash__text text-left break-words'>{moment(customersData.created_at).format('lll')}</p>
                        </div>
                    
                    </div>

                </div>  

                </div>

            </div>

            <section className='my-6 mt-8 lg:mt-12 lg:my-8 '>Policies</section>
            <CustomerPolicyTable authId={id}/>
            </>
         )} 
        </> 
        
    )
}

export default CustomerItem
