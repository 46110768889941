import React, {useEffect, useState, useContext} from 'react';
import {useForm} from '../../../../hooks/form-hook';
// import {useHttpClient} from '../../../../hooks/http-hook';
import { useHttpClient } from '../../hooks/dashboard-http-hook';
import Input from '../../../../UI_Elements/Input/Input';
import Loader from '../../../../UI_Elements/loader/Loader';
import { AuthContext } from '../../../../context/auth-context';

import {VALIDATOR_MINLENGTH} from '../../../../util/validators';

function Password() {

    const auth = useContext(AuthContext)

    const [loadedData,
        setLoadedData] = useState(null);

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({
            password: {
                value: '',
                isValid: false
            },
            confirmPassword: {
                value: '',
                isValid: false
            }

    }, false);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/getData`, "GET", null, {Authorization: 'Bearer ' + auth.token});
                setLoadedData(responseData);

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, auth]);

    const accountSubmitHandler = async e => {
        e.preventDefault();

        try {

            const data = JSON.stringify({password: formState.inputs.password.value, password_confirmation: formState.inputs.confirmPassword.value})
            await
            sendRequest(`${process.env.REACT_APP_API_CALL}/api/admins/editpassword`, 'POST', data, {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token
            });

        } catch (err) {
            console.log(error)
        }
    }

    return (
        <div className='p-4 md:p-12 bg-white w-full'>
            <div>
                <form onSubmit={accountSubmitHandler}>
                    {loadedData && <div className='grid grid-cols-1 gap-6 md:gap-8 '>
                        <div className='w-5/5 lg:w-3/5 mx-auto'>
                        <div className='mb-8'>
                        <Input
                            label="Old Password"
                            onInput={inputHandler}
                            initialValue={loadedData.admin.firstname}
                            key="old_password"
                            type="password"
                            className="dash-p-4"
                            element="input"
                            disabled />
                        </div>

                        <div className='mb-8'>
                        <Input
                            label="New Password"
                            onInput={inputHandler}
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            key="new_password"
                            type="password"
                            className="dash-p-4"
                            element="input"/>
                        </div>

                        <Input
                            label="Confirm New Password"
                            onInput={inputHandler}
                            validators={[VALIDATOR_MINLENGTH(8)]}
                            key="confirm_password"
                            type="password"
                            className="dash-p-4"
                            element="input"/>

                        </div>

                        <div className='my-8 mx-auto flex justify-start items-center'>
                            <input
                                type='submit'
                                className={`form__submit text-sm py-3 px-8 md:px-16 lg:px-24 text-white mr-2 `}
                                value="Save Changes"/> {isLoading && <Loader/>}
                        </div>

                    </div>
}
                </form>
            </div>
        </div>
    );
}

export default Password;
