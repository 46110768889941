import React, {useState} from 'react'

import CompanyTable from '../Components/Companies/CompanyTable';
import AddCompanyModal from '../Components/Companies/AddCompanyModal';

function Policies(){

    const [addCompany, setAddCompany] = useState(false)

    const addCompanyHandler = () =>{
        setAddCompany(!addCompany)
    }
    return(
        <>
            <AddCompanyModal show={addCompany} onCancel={addCompanyHandler}/>
            <CompanyTable onAddCompanyHandler={addCompanyHandler}/>
        </>
        
    )

}

export default Policies;