import React, { useReducer, useEffect } from 'react';

import { validate } from '../../../../util/validators';

import './input.css'

const inputReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE':
            return {
                ...state,
                value: action.val,
                isValid: validate(action.val, action.validators)
            };
        case 'TOUCH':
            {
                return {
                    ...state,
                    isTouched: true
                }
            }
        default:
            return state;
    }
};

function Input(props) {
    
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || '',
        isTouched: false,
        isValid: props.initialValid || false
      });

      const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid)
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    dispatch({
      type: 'CHANGE',
      val: event.target.value,
      validators: props.validators
    });
  };

  const touchHandler = () => {
    dispatch({
      type: 'TOUCH'
    });
  };

  

  const element = props.element === 'input'
        ? (<input
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
            className={`border-2 p-2 rounded-sm border-slate-300 bg-white input_button ${props.className}`}/>)
        : (<textarea
            id={props.id}
            rows={props.rows || 3}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={inputState.value}
            placeholder={props.placeholder}
            className={`border-2 p-2 rounded-sm border-slate-300 bg-white input_button ${props.className}`}/>);

    return (
        <div className={`flex flex-col ${props.classNameDiv}`}>
            <label className='text-sm mb-2' htmlFor={props.id}>{props.label}</label>
              {element}
            {!inputState.isValid && inputState.isTouched && <p className='text-red-600'>{props.errorText}</p>}
        </div>
    )
}

export default Input
