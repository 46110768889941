import React, {useContext, useState, useEffect} from 'react'
import { Route, Routes, Navigate } from "react-router-dom";


import Sidebar from './Sidebar/Sidebar';

import AdminNavbar from '../../default/header/Dashboard/Navbars/AdminNavbar';

import DashboardOverview from './Sections/Dashboard';

import Policies from './Sections/Policies';
import Companies from './Sections/Companies';
import Customers from './Sections/Customers';
import Settings from './Sections/Settings';
import Claims from './Sections/Claims';
import Payments from './Sections/Payments';


import {AuthContext} from '../../context/auth-context';
import {DataContext} from './context/DataContext';
import { useHttpClient } from './hooks/dashboard-http-hook';

import './dashboard.css'
import Loader from './UI_Elements/Loader/Loader'
import ClaimsItem from './Components/Claims/ClaimsItem';
import CompanyItem from './Components/Companies/CompanyItem';
import CustomerItem from './Components/Customers/CustomerItem';


function Dashboard() {
    const auth = useContext(AuthContext);  

    const [data,setData] = useState({
        firstname:"",
        lastname:"",
        email:"",
        image:null,
        phoneNumber:null,
        pin:null,
        admin_type:""
    })

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/getData`, "GET", null, {Authorization: 'Bearer '+auth.token});

                setData((data)=>(
                    {
                        ...data,
                        firstname:responseData.admin.firstname,
                        lastname:responseData.admin.lastname,
                        email:responseData.admin.email,
                        image:responseData.admin.image,
                        phoneNumber:responseData.admin.phone_number,
                        pin:responseData.admin.transaction_pin,
                        admin_type:responseData.admin.admin_type
                    }
                ))

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, auth]); 

    return (
        <>   
        {isLoading ? <Loader/> : 
            <DataContext.Provider 
            value={{
                firstname:data.firstname,
                lastname:data.lastname,
                email:data.email,
                phonenumber:data.phoneNumber,
                image:data.image,
                transactPin:data.pin,
                adminType:data.admin_type
            }}>
           
            <Sidebar/>
            <div className="relative md:ml-48 xl:ml-64 dashboard__background ">
                <AdminNavbar />
                <div className="px-4 mx-auto w-full mt-8 dashboard__background dashboard__height" >
                <Routes>
                    <Route path='dashboard' exact element={<DashboardOverview/>} />
                    <Route path='payments' exact element={<Payments/>} />
                    <Route path='claims' exact element={<Claims/>} />
                    <Route path='claims/:id' exact element={<ClaimsItem/>} />
                    <Route path='policies' exact element={<Policies/>} />
                    <Route path='companies' exact element={<Companies/>} />
                    <Route path='companies/:id' exact element={<CompanyItem/>} />
                    <Route path='customers' exact element={<Customers/>} />
                    <Route path='customers/:id' exact element={<CustomerItem/>} />
                    <Route path='settings' exact element={<Settings/>} />
                    <Route path="*" element={<Navigate to ="dashboard" />}/>
                </Routes>
                </div>

        </div>

        </DataContext.Provider>
        }
            
        </>
    )
}

export default Dashboard