import React from 'react';

import PaymentTable from '../Components/Payments/PaymentTable';
import HOC from '../HOC/HOC';

function Payments() {
  return (
      <HOC>
        <PaymentTable/>
      </HOC>
  )
}

export default Payments;
