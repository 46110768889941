import React, {useMemo, useEffect, useState, useContext, useCallback} from 'react'
import { useNavigate } from 'react-router-dom';

// REACT HOOKS
import {useTable, usePagination} from 'react-table'


import * as Icons from 'react-icons/io'
import * as IconT from 'react-icons/bi'

import { useHttpClient } from '../../hooks/dashboard-http-hook'
import {AuthContext} from '../../../../context/auth-context';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'

// DATA
import {CUSTOMER_COLUMNS} from '../../util/columns';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';



function CustomerTable(props) {    

    const auth = useContext(AuthContext)

    const [query,
        setQuery] = useState("");

    const history = useNavigate()
    

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"20",
        "status":"1",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append("search_text", detailsData.search_text)

        const fetchPolicies = async() => {
            
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/customers/list?page=${detailsData.current_page}`, "POST", formData , {Authorization: 'Bearer '+auth.token});
                
                setTableData(responseData.customers);
                setPages(responseData.customers.links)

                if(responseData){
                    setCellData([])
                    for(let file of responseData.customers.data){
                        setCellData((cell)=>[...cell, {
                            "name":`${file.firstname} ${file.lastname}`,
                            "email": file.email,
                            "phone_number": file.phone_number,
                            "policies": file.policycount,
                            "id":file.authId
                           
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }



    const columns = useMemo(() => CUSTOMER_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    const handler = () => {
        setDetailsData({
            ...detailsData,
            search_text:query
        })
    }

    const singleCustomer = useCallback((e, id) =>{
        e.preventDefault()
        console.log(id)
        history(id)
    },[history])

    return (
        <>
        {isLoading && <Backdrop overlay/>}

        <div className='flex justify-between items-center flex-wrap mb-8'>
            <div
                    className='flex items-center justify-between rounded-full shadow-sm m-2 p-3 w-full lg:w-2/5 xl:w-1/5 bg-white'>
                    <input
                        type="text"
                        className='text-sm outline-none flex-grow'
                        placeholder='search...'
                        id='search_select'
                        onInput={(e) => {
                        e.preventDefault();
                        setQuery(e.target.value);
                        handler();
                    }}/>
                    <label htmlFor='search_select'>
                        <IconT.BiSearch className='text-slate-500'/>
                    </label>
                </div>

                <button className='dash_button_red px-4 xl:px-6 py-2 rounded text-center text-sm dash_button_pointer' onClick={props.onAddPolicyHandler}>
                + Add Customer
                </button>

        </div>
        
        <div className="bg-white pb-16 shadow rounded-xl mt-8 lg:mt-4 overflow-x-auto w-full xl:w-4/5">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs">{column.render('Header')}</th>
                                ))}
                            <th></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}
                                <td className='py-1 px-4 m-2 bg-transparent cursor-pointer ' onClick={(e) => singleCustomer(e,row.original.id)}><Icons.IoIosArrowDroprightCircle className="dash_action_button text-base"/></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
           {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}
            
        </div>
        </>              
    )
}

export default CustomerTable
