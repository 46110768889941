import React from 'react';


import Modal from '../../../../UI_Elements/Modal/Modal'

import OTP from '../../UI_Elements/OTP/OTP'

function DeleteClaimsModal(props) {

    
  return (
      <Modal show={props.show} onCancel={props.onCancel} >
          <div className="bg-white p-2 md:p-8">
            <div className='flex flex-col justify-center items-center'>
                <h1 className="dash__blue__color text-center text-xl mb-4 font-semibold">Are you sure you want to Delete this claim?</h1>
                <p className='text-sm text-center opacity-70'>This action is not advisable and cannot be reversed. </p>
                <div className='my-6 md:my-12 flex flex-col justify-center items-start md:items-center md:w-4/5'>
                  <p className='mb-2 text-left w-full lg:w-4/5'>Enter PIN</p>
                  <OTP otp={props.otp} otpHandler={props.otpHandler}></OTP>
                </div>
                <button className='dash_button_red py-3 px-8 md:px-16 lg:px-24 rounded dash_button_pointer' onClick={props.onDelete}>Delete Claim</button>
            </div>
        </div>
      </Modal>
  )
}

export default DeleteClaimsModal;
