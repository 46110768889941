import React, {useContext, useEffect, useState} from 'react';

import {useForm} from '../../../../hooks/form-hook';

import * as Icons from 'react-icons/fi'

import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../../../../util/validators';

import Input from '../../../../UI_Elements/Input/Input';

import PhoneInput from 'react-phone-number-input';
import { useHttpClient } from '../../hooks/dashboard-http-hook';
import Loader from '../../../../UI_Elements/loader/Loader';
import { AuthContext } from '../../../../context/auth-context';
import Avatar from '../../UI_Elements/Avatar/Avatar';

function Account() {

    const auth = useContext(AuthContext)

    const [loadedData, setLoadedData] = useState(null);

    const [phoneValue,
        setphoneValue] = useState(null);

    const [profileImage,
            setProfileImage] = useState(null);

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler, setFormData] = useForm({
            firstname: {
                value: '',
                isValid: false
            },  
            lastname: {
                value: '',
                isValid: false
            },
            phonenumber: {
                value: '',
                isValid: false
            },
            email: {
                value: '',
                isValid: false
            }

    }, false);

    useEffect(() => {
        const fetchData = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/getData`, "GET", null, {Authorization: 'Bearer '+auth.token});
                setLoadedData(responseData);
                
                setFormData(
                    {
                      firstname: {
                        value: responseData.admin.firstname,
                        isValid: true
                      },
                      lastname: {
                        value: responseData.admin.lastname,
                        isValid: true
                      },
                      email: {
                        value: responseData.admin.email,
                        isValid: true
                      },
                      phonenumber: {
                        value: responseData.admin.phone_number,
                        isValid: true
                      }
                    },
                    true
                  );

                  setphoneValue(responseData.admin.phone_number)

                  setProfileImage(responseData.admin.image)

                  console.log(responseData.customer)

            } catch (err) {
                console.log(error)
            }
        };
        fetchData();
    }, [sendRequest, error, setFormData, auth]);


    const readerHandler = (file) =>{
        const reader = new FileReader();
        reader.onload = (e) => {
            setProfileImage(e.target.result)
        }

        reader.readAsDataURL(file)
    }

    const profileHandler = async(e) =>{

        await readerHandler(e.target.files[0]);

        const formData = new FormData()

        formData.append('image',e.target.files[0]);
        formData['image'] = e.target.files[0]

        const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/uploadImage`, "POST", formData, {Authorization: 'Bearer '+auth.token});
        console.log(responseData)

    }

    const accountSubmitHandler = async e => {
        e.preventDefault();

        try {

            const data = JSON.stringify({
                firstname: formState.inputs.firstname.value,
                lastname: formState.inputs.lastname.value,
                email: formState.inputs.email.value,
                phone_number: phoneValue
            })
            await
            sendRequest(`${process.env.REACT_APP_API_CALL}/admins/editprofile
            `, 'POST', data,{'Content-Type': 'application/json', Authorization: 'Bearer '+auth.token});
            

        } catch (err) {
            console.log(error)
        }
    }
    return (
        <div className='p-4 md:p-12 bg-white w-full'>
        {loadedData && 
            <div>
            <div className='w-full mx-auto mb-8 flex justify-center items-center '>
                    <div className='relative'>
                    <Avatar src={profileImage} alt="account profile" name={loadedData.admin.firstname} className="mx-auto w-16 h-16 md:w-20 md:h-20 rounded-full " profileClass="rounded-full w-16 h-16 md:w-20 md:h-20 bg-primary-red flex justify-center item-center"/>
                    <label htmlFor='profile' className='bg-primary-black p-2 rounded-full absolute bottom-2 -right-1 dash_border_white z-20 cursor-pointer'>
                        <Icons.FiCamera className=' rounded-full text-xs md:text-base text-white' /> 
                    </label>
                    </div>
                    <input type="file"
                className='w-1 h-1 opacity-0 ' accept='image/*' id='profile' onChange={profileHandler}/>

            </div>
            <div>
                <form onSubmit={accountSubmitHandler} >
                   
                    <div className='grid lg:grid-cols-2 gap-6 md:gap-8 '>
                    <Input
                        label="First Name"
                        onInput={inputHandler}
                        initialValue={loadedData.admin.firstname}
                        initialValid={true}
                        validators={[VALIDATOR_REQUIRE()]}
                        id="firstname"
                        placeholder="First Name"
                        type="text"
                        className="dash-p-4" 
                        element="input"/>
                    <Input
                        label="Last Name"
                        onInput={inputHandler}
                        initialValue={loadedData.admin.lastname}
                        initialValid={true}
                        id="lastname"
                        placeholder="Last Name"
                        validators={[VALIDATOR_REQUIRE()]}
                        type="text"
                        className="dash-p-4"
                        element="input"/>
                    <Input
                        label="Email Address"
                        onInput={inputHandler}
                        initialValue={loadedData.admin.email}
                        initialValid={true}
                        validators={[VALIDATOR_EMAIL()]}
                        id="emailaddress"
                        placeholder="Email Address"
                        type="text"
                        className="dash-p-4"
                        element="input"/>
                    <div className='flex flex-col'>
                        <label className='text-sm mb-2'>Phone Number</label>
                        <div className='border-2 p-2 rounded-sm border-slate-300 bg-white input_button'>
                            <PhoneInput
                                className="border-slate-300"
                                defaultCountry="NG"
                                withCountryCallingCode
                                placeholder="Enter phone number"
                                value={loadedData.admin.phone_number || phoneValue}
                                onChange={setphoneValue}/>
                        </div>
                    </div>

                    <div className='my-8 mx-auto flex justify-start items-center lg:col-span-2'>
                        <input
                            type='submit'
                            className={`form__submit text-sm  py-3 px-8 md:px-16 lg:px-24 text-white mr-2 `}
                            value="Save Changes" 
                            /> {isLoading && <Loader/>}
                    </div>

                    </div>
                    
                </form>
            </div>
            </div>
            }
        </div>
    )
}

export default Account;
