import React, { useContext,useState } from 'react';

import {AuthContext} from '../../../../context/auth-context';
import {useHttpClient} from '../../../../hooks/http-hook';

import Modal from '../../../../UI_Elements/Modal/Modal'

import OTP from '../../UI_Elements/OTP/OTP'
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop';
// import { useNavigate } from 'react-router-dom';

function DeletePolicyModal(props) {
  // const history = useNavigate()
  const [otp, setOtp] = useState("")
  const [refresh, setRefresh] = useState(false)

  const auth = useContext(AuthContext)

  const {isLoading, error, sendRequest} = useHttpClient();

  const otpHandler = (otp) =>{
    setOtp(otp)
  }

  const onDelete = async (e) => {
    e.preventDefault()

    const formData = new FormData();

    formData.append("policy_id", props.policy_id)
    formData.append("transaction_pin", otp)

    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/policy/delete`, "POST",formData, {Authorization: 'Bearer '+auth.token});

      if(responseData){
        setRefresh(!refresh)
      }

  } catch (err) {
      console.log(error)
  }
  }
    
  return (
      <Modal show={props.show} onCancel={props.onCancel} >
        {isLoading && <Backdrop/>}
          <div className="bg-white p-2 md:p-8">
            <div className='flex flex-col justify-center items-center'>
                <h1 className="dash__blue__color text-center text-xl mb-4 font-semibold">Are you sure you want to <span className='text-bold'>DELETE</span> this Policy?</h1>
                <p className='text-sm text-center opacity-70'>This action is not advisable and cannot be reversed. </p>
                <div className='my-6 md:my-12 flex flex-col justify-center items-start md:items-center md:w-4/5'>
                  <p className='mb-2 text-left w-full lg:w-4/5'>Enter PIN</p>
                  <OTP otp={otp} otpHandler={otpHandler}></OTP>
                </div>
                <button className='dash_button_red my-4 py-3 px-8 md:px-16 lg:px-24 rounded dash_button_pointer' onClick={onDelete}>Delete Policy</button>
            </div>
        </div>
      </Modal>
  )
}

export default DeletePolicyModal;
