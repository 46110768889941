import React, {useMemo, useEffect, useState, useContext, useCallback} from 'react'
// import { useNavigate } from 'react-router-dom';
// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import moment from 'moment';
import csvDownload from 'json-to-csv-export'

import * as IconT from 'react-icons/io'
import * as IconB from 'react-icons/bi'
import * as IconG from 'react-icons/gr'

import { useHttpClient } from '../../hooks/dashboard-http-hook'
import {AuthContext} from '../../../../context/auth-context';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'
import SelectTable from '../../UI_Elements/Select/SelectTable'

// DATA
import {CLAIMS_COLUMNS} from '../../util/columns';
import { useNavigate } from 'react-router-dom';
import TablePagination from '../../UI_Elements/TablePagination/TablePagination';



function CardTable() {    

    const auth = useContext(AuthContext);

    const history = useNavigate()

    const [query,
        setQuery] = useState("");

    const [exportData, setExportData] = useState([])
    
    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"20",
        "id":null,
        "search_text": ""
    })

    const [pages, setPages] = useState([]);


    const statusOptions = ["Settled" , "Pending", "Decline"];


    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const data = JSON.stringify({
            "page_number": detailsData.page_number,
            "id": detailsData.id,
            "search_text": detailsData.search_text
        })

        const fetchPolicies = async() => {
            
            try {   
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/claims/list?page=${detailsData.current_page}`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
                if(responseData){
                    
                    setTableData(responseData.claims);
                    setPages(responseData.claims.links)
                    
                }
                

                if(responseData){
                    setCellData([])
                    for(let file of responseData.claims.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.claim_id,
                            "date": moment(file.created_at).format('lll'),
                            "customer": `${file.customer.firstname} ${file.customer.lastname}`,
                            "policy": file.policyname.policy.policy_name,
                            "insurer": file.insurer,
                            "circumstance": file.circumstance,
                            "status": file.status
                        }])

                        setExportData((cell)=>[...cell, {
                            "id":file.claim_id,
                            "date": moment(file.created_at).format('lll'),
                            "customer": `${file.customer.firstname} ${file.customer.lastname}`,
                            "policy": file.policyname.policy.policy_name,
                            "insurer": file.insurer,
                            "circumstance": file.circumstance,
                            "status": file.status === "0" ? "Pending" : file.status === "1" ? "Settled" : "decline"
                        }])
                    }
                }



            } catch (err) {
                
                console.log(error)
            }
        };
        fetchPolicies();

    }, [sendRequest, error, auth, detailsData]);


    


    const singleClaim = useCallback((e, id) =>{
        e.preventDefault()
        history(id)
    },[history])


    const onStatusChangeHandler = useCallback(async(item,id) =>{
       
        const data = JSON.stringify({
            "status": item,
            "claim_id": id
        })

        
            
        try {   
            await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/claims/change/status`, "POST", data , {Authorization: 'Bearer '+auth.token, "Content-Type":'application/json'});
            


        } catch (err) {
            
            console.log(error)
        }
        
    },[auth, sendRequest, error])


    const statusNumber = useCallback((item, id) =>{
        let number;
        if(item === "Pending"){
            number = 0;

            return onStatusChangeHandler(number, id)
        }
        if(item === "Settled"){
            number = 1;

            return onStatusChangeHandler(number, id)
        }
        if(item === "Decline"){
            number = -1;

            return onStatusChangeHandler(number, id)
        }
    },[onStatusChangeHandler])


    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const columns = useMemo(() => CLAIMS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    const handler = () => {
        setDetailsData({
            ...detailsData,
            search_text:query.toLowerCase()
        })
    }


    

    return (
        <>
        {isLoading && <Backdrop overlay/>}
        <div className='flex justify-between items-center flex-wrap mb-8'>
            <div
                    className='flex items-center justify-between rounded-full shadow-sm m-2 p-3 w-full lg:w-2/5 xl:w-1/5 bg-white'>
                    <input
                        type="text"
                        className='text-sm outline-none flex-grow'
                        placeholder='search...'
                        id='search_select'
                        onInput={(e) => {
                        e.preventDefault();
                        setQuery(e.target.value);
                        handler();
                    }}/>
                    <label htmlFor='search_select'>
                        <IconB.BiSearch className='text-slate-500'/>
                    </label>
                </div>

                <div className="flex justify-center items-baseline text-primary-black mt-2 ml-2 md:mt-0 md:ml-0 cursor-pointer" onClick={() => csvDownload(exportData, "ClaimsData.csv")}>
                    <IconG.GrDownload className='text-sm mr-2'/>
                    <p className='text-sm' >Export table</p>
                </div>  

        </div>

        <nav className="dash_border-b my-4  w-full">
            <button className={` py-2 px-6  dash__color ${detailsData.id === null && "border-b-2 border-primary-red"}`}  onClick={
                () =>{
                    setDetailsData(data =>({
                        ...data,
                        "id":null
                    }))
                }
            }>All</button>
            <button className={` py-2 px-6  dash__color ${detailsData.id  === "0"  && "border-b-2 border-primary-red"}`} onClick={
                () =>{
                    setDetailsData(data =>({
                        ...data,
                        "id":"0"
                    }))
                }
            }>Pending </button>
            <button className={` py-2 px-6  dash__color ${detailsData.id === "1" && "border-b-2 border-primary-red"}`} onClick={
                () =>{
                    setDetailsData(data =>({
                        ...data,
                        "id":"1"
                    }))
                }
            }>Settled</button>
            <button className={` py-2 px-6  dash__color ${detailsData.id === "-1" && "border-b-2 border-primary-red"}`} onClick={
                () =>{
                    setDetailsData(data =>({
                        ...data,
                        "id":"-1"
                    }))
                }
            }>Declined</button>
        </nav>
        
        <div className="bg-white pb-16 shadow-md sm:rounded-lg mt-16 overflow-x-auto">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} className="text-xs">{column.render('Header')}</th>
                                ))}
                            <th >{' '}</th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()}>

                                {row
                                    .cells
                                    .map(cell => {

                                        if(cell.value === "0"){
                                            return <td {...cell.getCellProps()} ><SelectTable options={statusOptions} id={row.cells[0].value} value="Pending" onInput={statusNumber}/></td>
                                        }

                                        if(cell.value === "1"){
                                            return <td {...cell.getCellProps()} ><SelectTable options={statusOptions} id={row.cells[0].value} value="Settled" onInput={statusNumber}/></td>
                                        }

                                        if(cell.value === "-1"){
                                            return <td {...cell.getCellProps()} ><SelectTable options={statusOptions} id={row.cells[0].value} value="Decline" onInput={statusNumber}/></td>
                                        }
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-6 whitespace-nowrap">{cell.render('Cell')}</td>
                                    })}
                                <td className='py-4 px-6 m-2 bg-transparent cursor-pointer text-sm whitespace-nowrap' onClick={(e) => singleClaim(e,row.cells[0].value)}><IconT.IoIosArrowDroprightCircle className="dash_action_button text-base"/></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}
            
        </div>
        </>              
    )
}

export default CardTable
