import React from 'react'

import * as Icons from 'react-icons/md'

function TablePagination(props) {
  return (
    <div className='flex justify-center items-center w-full mt-8 pl-4 md:pl-0'>
                    
                <div className="w-full justify-center items-center flex">
                <span>
                        PAGE{' '}
                        <span className='mr-2'>
                            {props.current_page}
                            {' '} of {props.last_page}
                        </span>{' '}
                    </span>
                    {' '}
                    <button className={`${props.prev_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(props.first_page_url)} >
                        <Icons.MdArrowBackIos className={`${props.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-bold`}/>
                        <Icons.MdArrowBackIos className={`${props.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold -ml-2`}/>
                    </button>{' '}
                    <button className={`${props.prev_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(props.prev_page_url)} >
                        <Icons.MdArrowBackIos className={`${props.prev_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
                    </button>{' '}

                    {
                        props.pages.map((option, index) => {
                            if(option.label === "&laquo; Previous" || option.label === "Next &raquo;"){
                                return (
                                    <div key={index}>{""}</div>
                                )
                            }
                            return(
                                <React.Fragment key={index}>
                                <button  className={`${option.active === true ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(option.url)}>
                                    {option.label}
                                </button>{' '}
                                </React.Fragment>
                            )
                        })
                    }
                    
                    <button className={`${props.next_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(props.next_page_url)} >
                        <Icons.MdArrowForwardIos className={`${props.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`}/>
                    </button>{' '}
                    <button className={`${props.next_page_url !== null ? 'dash_cell_bg' : 'bg-white dash_disable_border'} h-8 w-8 dash_border_radius inline-flex justify-center items-center`} onClick={() => props.pageChangeHandler(props.last_page_url)} >
                        <Icons.MdArrowForwardIos className={`${props.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-bold -mr-2`}/>
                        <Icons.MdArrowForwardIos className={`${props.next_page_url !== null ? 'dash_cell_color' : 'dash_color_disabled'} font-extrabold`} />
                    </button>{' '}

                    
                    <span className='mt-2 md:mt-0 ml-14   sm:ml-8'>
                        View{' '}
                        <select
                            value={props.per_page}
                            onChange={e => props.pageSizeChangeHandler(Number(e.target.value))}
                            className='select__border p-2 px-4'>
                            {[
                                5,
                                10,
                                15,
                                20
                            ].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        {' '}
                        per page
                    </span>
                </div>
            </div>
  )
}

export default TablePagination