import React, {useState, useContext, useEffect} from 'react';

import axios from 'axios';

import {AuthContext} from '../../../../context/auth-context';

import {VALIDATOR_MINLENGTH} from '../../../../util/validators'

import {useForm} from '../../../../hooks/form-hook'
import { useHttpClient } from '../../../../hooks/http-hook'

import Modal from '../../../../UI_Elements/Modal/Modal';
import Input from '../../../../UI_Elements/Input/Input';

import Loader from '../../UI_Elements/Loader/Loader'

import OTP from '../../UI_Elements/OTP/OTP'
import Avatar from '../../UI_Elements/Avatar/Avatar';

import {images} from '../../../../assests/index'

function EditCompanyModal(props) {

  const auth = useContext(AuthContext)
  const [otp, setOtp] = useState("")

  const [profileImage,
    setProfileImage] = useState(props.profileImage);

  const [sent,setSent] = useState(true);
  const [data,setData] = useState(true);

  const {isLoading, error, sendRequest} = useHttpClient();

  const [formState,
    inputHandler] = useForm({

    company: {
        value: '',
        isValid: false
    }
}, false);

  const {input} = props
  useEffect(()=> {
    input(data);
  }, [data, input]);
  
  const companySubmitHandler =async (e) =>{
    e.preventDefault()

    const formData = new FormData();

    formData.append("company_name", formState.inputs.company.value);
    formData.append("transaction_pin", otp);
    formData.append("image", profileImage);
    formData.append("company_id", props.company_id);


    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/insurance/edit`, "POST", formData , {Authorization: 'Bearer '+auth.token});
      
      if(responseData){
        setData(!data)
        props.onCancel()
      }


  } catch (err) {
      console.log(error)
  }
  }

  const otpHandler = (otp) =>{
    setOtp(otp)
  }

  const readerHandler = (file) =>{
    const reader = new FileReader();
    reader.onload = async(e) => {
        

        try{
          setSent(false)
          const formData = new FormData();
  
          formData.append("file", file);
          formData.append('upload_preset', 'leadfort-admins');
  
          
  
          await axios.post("https://api.cloudinary.com/v1_1/devgodfrey/image/upload",formData).then(res =>{
              
            setProfileImage(res.data.secure_url)
            setSent(true)
          return res.data.secure_url;
          })
      }catch(e){
          console.log(e)
      }
    }

    reader.readAsDataURL(file)
}

const profileHandler = async(e) =>{

    await readerHandler(e.target.files[0]);

}
    
  return (
    <>
    {isLoading && <Loader/>}
      <Modal show={props.show} onCancel={props.onCancel} onSubmit={companySubmitHandler} absolute >
          <div className="bg-white p-2 md:p-8">
          <h1 className="dash__blue__color text-center text-xl mb-4 font-semibold">Edit Company</h1>
            
          <div className='w-full mx-auto my-4 lg:my-8 flex justify-center items-center '>
                    <div className='relative'>
                    <Avatar src={profileImage ? profileImage : images.AvatarImage[0] } alt="account profile"  className={`mx-auto w-20 h-20 rounded-full ${!sent && "opacity-40"}`} profileClass={`rounded-full w-20 h-20 bg-primary-red flex justify-center item-center ${!sent && "opacity-40"}`}/>
                    {sent ? (<label htmlFor='profile' className='text-primary-red text-sm p-2 rounded-full dash_border_white cursor-pointer'>
                        Upload Logo 
                    </label>) : (<p className='text-primary-red text-sm'>Please kindly wait</p>)}
                    </div>
                    <input type="file"
                className='w-1 h-1 opacity-0 ' accept='image/*' id='profile' onChange={profileHandler}/>

            </div>

            <div className='flex flex-col justify-center items-center w-full' >

            <Input
                            type="text" 
                            placeholder='Enter company name'
                            label="Company Name"
                            id="company"
                            validators={[VALIDATOR_MINLENGTH(2)]}
                            errorText="Please enter a valid company"
                            onInput={inputHandler}
                            initialValue={props.company_name}
                            initialValid={true}
                            inputClassName="w-full"
                            containerClassName="w-full md:w-4/5 xl:w-3/5 "/>

                <div className='my-6 md:my-12 flex flex-col justify-center items-start md:items-center md:w-4/5'>
                  <p className='mb-2 text-left w-full lg:w-4/5'>Enter PIN</p>
                  <OTP otp={otp} otpHandler={otpHandler}></OTP>
                </div>
                <button className='dash_button_red py-3 px-8 md:px-16 lg:px-24 rounded dash_button_pointer' type='submit'>Edit Company</button>
            </div>
        </div>
      </Modal>
      </>
  )
}

export default EditCompanyModal;
