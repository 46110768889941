import React, {useState, useEffect, useContext} from 'react';

import axios from 'axios';
import Swal from 'sweetalert2';

import * as Icons from 'react-icons/bs'
import * as Iconr from 'react-icons/ri'

import {useHttpClient} from '../../hooks/dashboard-http-hook';

import {VALIDATOR_MAXLENGTH, VALIDATOR_REQUIRE} from '../../../../util/validators'

import { saveAs } from 'file-saver';

import {useForm} from '../../../../hooks/form-hook'

import Modal from '../../../../UI_Elements/Modal/Modal'
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'
import Input from '../../UI_Elements/Input/Input'

import OTP from '../../UI_Elements/OTP/OTP'

import {images} from '../../../../assests/index'

import {AuthContext} from '../../../../context/auth-context';

function EditPolicyModal(props) {

    const auth = useContext(AuthContext)

    const [otp,
        setOtp] = useState("")

    const [attachedFile,
        setAttachedFile] = useState(null);

    const [attachedUrl,
        setAttachedUrl] = useState(null);

    const [policiesData, setPoliciesData] = useState(null);

    const [sent,setSent] = useState(true);

    const [data,setData] = useState(true);

    const {isLoading, error, sendRequest} = useHttpClient();
    
    
    
    // useEffect(()=> {
    //     input(data);
    //   }, [data, input]);


    useEffect(() =>{
        const formData = new FormData()

        formData.append("attachment_id", props.attachedId)
        const fetchPolicies = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/policy/attachment/single`, "POST",formData, {Authorization: 'Bearer '+auth.token});
                setAttachedUrl(responseData.attachment.policy_document)
                setAttachedFile(responseData.attachment.policy_document)
                setPoliciesData(responseData.attachment)
                


            } catch (err) {
                console.log(error)
            }
        };
        fetchPolicies();
    },[sendRequest, error, auth, props.attachedId])

    const [formState,
        inputHandler] = useForm({

        rate: {
            value: '',
            isValid: false
        },
        description: {
            value: '',
            isValid: false
        }

    }, false);

    const policySubmitHandler = async(e) => {
        e.preventDefault();

        

        const formData = new FormData();

        formData.append("policy_id", policiesData.policy_id);
        formData.append("company_id", policiesData.company_id);
        formData.append("image", attachedUrl);
        formData.append("rate", formState.inputs.rate.value);
        formData.append("description", formState.inputs.description.value)
        formData.append("transaction_pin", otp)
        formData.append("amount", "0")
        formData.append("attachment_id", props.attachedId)

        for(let pair of formData.entries()){
            if (pair[1] === '' || pair[1] === null){
                let swalAlert = await Swal.fire('Error', `Missing ${pair[0]}`, 'error');
                if (swalAlert.isConfirmed) {
                    return
                }
            }
        }

        try{
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/policy/attachment/edit`, "POST", formData , {Authorization: 'Bearer '+auth.token});

            if(responseData){
                let swalAlert = await Swal.fire('Success', "Policy Attached", 'success');
                if (swalAlert.isConfirmed) {
                    setData(!data)
                    props.onCancel()
                }
            }
        }
        catch (err) {
            console.log(error)
        }

      
    }

    const otpHandler = (otp) => {
        setOtp(otp)
    }
    const downloadAttachedPolicyHandler = (e) => {

        saveAs(attachedFile, `{${attachedFile.name}}`)
    }

    const attachedPolicyHandler = async(e) => {

        if (e.target.files[0].size >= 50000000000 ){
            let swalAlert = Swal.fire('Error', `${e.target.files[0].name} is too large (${handleSize(e.target.files[0].size)})`, 'error');
            if (swalAlert.isConfirmed) {
                return
            }
        }


        try{

            setSent(false)
            const formData = new FormData();

            formData.append("file", e.target.files[0]);
            formData.append('upload_preset', 'leadfort-attachpolicy');

            

            await axios.post("https://api.cloudinary.com/v1_1/devgodfrey/auto/upload",formData).then(res =>{
            
                setAttachedUrl(res.data.secure_url)
                setSent(true)
                return res.data.secure_url;
            })

            setAttachedFile(e.target.files[0])
        } catch(e){
            console.log(e)
        }

        setAttachedFile(e.target.files[0])
        
    }

    const deleteAttachedPolicy = () => {
        setAttachedUrl(null)
        setAttachedFile(null);
       
    }


    const handleSize = (size) =>{
        if(size <= 1000000){
            return (size/1000).toFixed(2) + 'KB';
        }
        if(size === 1000000 || size <= 1000000000){
            return (size/1000000).toFixed(2) + 'MB';
        }
        if(size === 1000000000 || size <= 1000000000000){
            return (size/1000000000).toFixed(2) + 'GB';
        }
    }

    

    return (
        <>
        {isLoading && <Backdrop/>}
        {policiesData && (
            <Modal
            show={props.show}
            onCancel={props.onCancel}
            onSubmit={policySubmitHandler} 
            absolute>
            <div className="bg-white p-2 md:p-8 w-full">
                <h1 className="dash__blue__color text-center text-xl mb-4 lg:mb-8 font-semibold">Edit Attach Policy</h1>

                <div className='flex flex-col justify-center items-center w-full'>

                    <span className='mb-4 w-full md:w-4/5 xl:w-3/5'>
                        <div className=''>
                            <p className=''>Insurance Policy</p>
                            
                            <p className='text-left text-sm lg:text-base font-bold'>{props.policy}</p>
                        </div>

                        

                    </span> 

                    <div className=" w-full md:w-4/5 xl:w-3/5">
                            <Input
                                element="input"
                                type="number"
                                placeholder='Enter rate %'
                                label="Rate %"
                                id="rate"
                                initialValue={policiesData.rate}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a valid rate"
                                onInput={inputHandler}
                                />
                        </div>  


                    <div className="my-4 w-full md:w-4/5 xl:w-3/5">

                        <Input
                            placeholder='Describe your offerings'
                            label="Description"
                            id="description"
                            rows="5"
                            validators={[VALIDATOR_MAXLENGTH(50)]}
                            initialValue={policiesData.description}
                            errorText="Please enter less than 50 words"
                            onInput={inputHandler}
                            className="w-full"/>

                        <p className='text-slate-300 text-xs mt-2'>Max 50 words</p>
                    </div>

                    <div className='relative w-full md:w-4/5 xl:w-3/5'>
                        <p className='text-primary-black'>Upload Policy Document</p>
                        {!sent && <div className='absolute top-0 left-0 h-full w-full bg-black opacity-90 text-white flex flex-col justify-center items-center'>
                            <h3 className='text-sm md:text-base text-center font-bold'>Uploading Files</h3>
                            <p className='text-xs md:text-sm text-center'>Please Kindly wait for files to be uploaded</p>
                    </div>}
                        {!attachedFile || attachedFile === "null" ? (<label htmlFor="AttachPolicy" className='block p-4  pl-0 font-bold text-2xl text-primary-black opacity-50'>Upload Here</label>) : (
                            <div className='flex justify-start items-center'>
                            <div
                                className='flex justify-between items-center p-4 shadow-md rounded-lg bg-white cursor-pointer'>
                                <img
                                    src={images.AdobeDownload[0]}
                                    alt={images.AdobeDownload[1]}
                                    className="w-12 h-12 object-contain"/>
                                
                                {attachedFile.name && (
                                    <span className='mx-2 md:mx-4 flex flex-col justify-center items-start'>
                                        <p className='mb-1 text-primary-black text-sm'>{attachedFile.name.length<10 ? attachedFile.name : `${attachedFile.name.slice(0,10)}(..)`}</p>
                                        <p className='text-slate-300 text-xs'>{handleSize(attachedFile.size)}</p>
                                    </span>
                                )}

                                {!attachedFile.name && (
                                    <span className='mx-2 md:mx-4 flex flex-col justify-center items-start'>
                                        <p className='mb-1 text-primary-black  text-sm'>{attachedFile.split('/')[7].length<10 ? attachedFile.split('/')[7] : `${attachedFile.split('/')[7].slice(0,10)}(..)`}</p>
                                        
                                    </span>
                                )}
                                <Icons.BsArrowDown className="text-primary-red " onClick={downloadAttachedPolicyHandler}/>
                            </div>
                            <Iconr.RiDeleteBinLine className='text-primary-red ml-4 cursor-pointer' onClick={deleteAttachedPolicy}/>
                        </div>
                        )}

                        <input type="file" id="AttachPolicy" className='w-1 h-1 opacity-0 mt-6' onChange={attachedPolicyHandler}/>
                        <label
                            htmlFor="AttachPolicy"
                            className='dash__color py-2 px-4 rounded dash__border__otp cursor-pointer'
                            style={{
                            backGroundColor: "rgba(117, 117, 158, 0.2)"
                        }}>Choose files</label>
                    </div>

                    <div
                        className='my-6 md:my-12 flex flex-col justify-center items-start md:items-center md:w-4/5'>
                        <p className='mb-2 text-left w-full lg:w-4/5'>Enter PIN</p>
                        <OTP otp={otp} otpHandler={otpHandler}></OTP>
                    </div>
                    <button
                        className='dash_button_red py-3 px-8 md:px-16 lg:px-24 rounded dash_button_pointer'
                        type='submit'>Update</button>
                </div>
            </div>
        </Modal>
        )}
        </>
    )
}

export default EditPolicyModal;
