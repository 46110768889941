import React, { useEffect, useContext, useState } from 'react'

import {Link, useParams } from 'react-router-dom';
import moment from 'moment'

import {AuthContext} from '../../../../context/auth-context';
import {images} from '../../../../assests/index';
import * as IconT from 'react-icons/io';

import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop'


import {useHttpClient} from '../../hooks/dashboard-http-hook';
import DeleteCompanyModal from './DeleteCompanyModal';
import AttachPolicyModal from './AttachPolicyModal';
import EditCompanyModal from './EditCompanyModal';


import CompanyPolicyTable from './CompanyPolicyTable';
import CompanyCustomerTable from './CompanyCustomerTable'


import Avatar from '../../UI_Elements/Avatar/Avatar';
// import EditPolicyModal from './EditPolicyModal';

function CompanyItem() {
    const {id} = useParams();
    const auth = useContext(AuthContext)

    const [insuranceData, setInsuranceData] = useState(null);

    const [tableData, setTableData] = useState(null)

    const [profileImage,
        setProfileImage] = useState(null);

    const { error, sendRequest} = useHttpClient();

    const [deleteModal, setdeleteModal] = useState( false);

    const [attachModal, setAttachModal] = useState(false)

    const [editModal, setEditModal] = useState(false)

    // const [editPolicy, setEditPolicyModal] = useState({
    //     show:false,
    //     policy_name:"",
    //     id:""
    // })

    const [refresh, setRefresh] = useState(true)


    useEffect(() => {


        const fetchItem = async() => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/admins/insurance/single/${id}`, "GET",null, {Authorization: 'Bearer '+auth.token});
                setInsuranceData(responseData.insurance)
                setProfileImage(responseData.insurance.image)
                setTableData(responseData)
                
                

            } catch (err) {
                console.log(error)
            }
        };
        fetchItem();

    }, [sendRequest, error, auth, id, refresh]);


    const cancelDeleteModalHandler =  () => {
        setdeleteModal(!deleteModal) 
        
    }

    const cancelAttachModalHandler = () => {
        setAttachModal(!attachModal) 
    }

    const cancelEditCompanyModalHandler = () => {
        setEditModal(!editModal) 
    }

    const refreshHandler = (item) =>{
        setRefresh(item)
    }
    

    
    return (
        
            <>

         {insuranceData === null  ? <Backdrop/> : ( 
            <>
            <DeleteCompanyModal show={deleteModal} onCancel={cancelDeleteModalHandler} company_id={insuranceData.company_id}/>
            <AttachPolicyModal show={attachModal} onCancel={cancelAttachModalHandler} company_id={insuranceData.company_id} input={refreshHandler}/>

            <EditCompanyModal
            show={editModal} 
            onCancel={cancelEditCompanyModalHandler} 
            company_name={insuranceData.company_name} 
            profileImage={insuranceData.image} 
            company_id={insuranceData.company_id} 
            input={refreshHandler}/>

            {/* <EditPolicyModal show={editPolicy.show} attachedId={editPolicy.id} policy={editPolicy.policy_name}/> */}

            <div className='flex flex-col md:flex-row justify-between items-center'>
                <Link to="../companies" className="flex justify-center items-center" >
                    <IconT.IoIosArrowDropleftCircle className="dash_action_button text-base mr-2"/>
                    <p className="dash__color text-base font-normal">All Companies</p>
                </Link>

                <div className='flex flex-col md:flex-row justify-center items-center mt-4   md:mt-0'>     
                    <button className='py-2 px-6 md:px-8 dash_button_buy text-sm rounded md:mr-4 dash_button_pointer' onClick={cancelDeleteModalHandler}>Delete Company</button>
                    <button className='py-2 px-6 md:px-8 dash_button_red text-sm rounded mt-4 md:mt-0 dash_button_pointer' onClick={cancelAttachModalHandler}>+ Attach Policy</button>
                </div>
                

            </div>

            
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-4">
                <div className='col-span-3 relative'>

                <div className=' px-6 py-8 rounded-xl shadow bg-white'>
                    
                    <span className='flex justify-between items-center flex-wrap sm:flex-nowrap dash_border-b '>
                        <h1 className="pb-2 dash__color w-full">Company Info</h1>
                        <button className='flex justify-center items-start' onClick={cancelEditCompanyModalHandler}>
                            <img src={images.editIcon[0]} alt={images.editIcon[1]} className="w-4 h-4 object-contain"/>
                            <p className='text-sm ml-2'>Edit</p>
                        </button>
                    </span>

                    
                    
                    <Avatar src={profileImage ? profileImage : images.AvatarImage[0] } alt="account profile"  className="mx-auto w-16 h-16 rounded-full absolute -top-4 left-3/4 sm:left-1/2" />
                    

                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 place-center gap-4'>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Name</h3>
                            <p className='dash__text text-left'>{insuranceData.company_name}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Date Registered</h3>
                            <p className='dash__text text-left'>{moment(insuranceData.created_at).format('lll')}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Policies Attached</h3>
                            <p className='dash__text text-left'>{insuranceData.attachment_count}</p>
                        </div>
                        <div className='py-4'>
                            <h3 className='dash__subtitle text-left mb-4'>Customers</h3>
                            <p className='dash__text text-left '>{insuranceData.customer_number}</p>
                        </div>
                    
                    </div>

                </div>

                {insuranceData && (
                    <>
                        <section className='my-6 mt-8 lg:mt-12 lg:my-8 '>Policies</section>
                        <CompanyPolicyTable company_id={insuranceData.company_id} refresh/>

                        <section className='my-6 mt-8 lg:mt-12 lg:my-8 '>Customers</section>
                        <CompanyCustomerTable company_id={insuranceData.company_id}/>
                    </>
                )}


                </div>
                

                <section className='rounded-lg bg-white p-4 shadow dash__border__div h-fit'>
                    <h3 className='dash__color mb-2'>
                        Policies Customer Ratio
                    </h3>
                    {tableData && tableData.recently_added && (
                        <div>
                        {tableData.recently_added.map((item, index) =>{
                            return (
                                <div className='flex justify-between items-center p-2 dash_border-t' key={index}>
                                    <h3 className=' text-primary-black py-2 opacity-60 text-xs xl:text-sm'>{item.policy.policy_name}</h3>
                                    <p className=''>{item.count}</p> 
                                </div>
                            )
                        })}


                    </div>
                    )}
                    

                </section>
            


                



            </div>
            </>
         )} 
        </> 
        
    )
}

export default CompanyItem
